export const SET_SESSION_ID = "SET_SESSION_ID";
export const SET_SESSION_DATE = "SET_SESSION_DATE";
export const SET_SCENARIO_NAME = "SET_SCENARIO_NAME";
export const SET_SCENARIO_FROM = "SET_SCENARIO_FROM";
export const SET_SCENARIO_TO = "SET_SCENARIO_TO";

type Payload = {
  type: string;
  payload: any;
};

export const reducer = (state: any, { type, payload }: Payload) => {
  switch (type) {
    case SET_SESSION_ID:
      return {
        ...state,
        sessionId: payload,
      };
    case SET_SESSION_DATE:
      return {
        ...state,
        sessionDate: payload,
      };
    case SET_SCENARIO_NAME:
      return {
        ...state,
        scenarioName: payload,
      };
    case SET_SCENARIO_FROM:
      return {
        ...state,
        scenarioFrom: payload,
      };
    case SET_SCENARIO_TO:
      return {
        ...state,
        scenarioTo: payload,
      };

    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};
