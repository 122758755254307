import styled from "styled-components";

type LegendIconProps = {
  type: string;
  color: string;
};

export const LegendStyle = {
  Wrapper: styled.ul`
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;

    @media only screen and (max-width: 767px) {
      display: block;
      width: 100%;
    }

    @media only screen and (min-width: 1550px) {
      position: absolute;
      right: 1rem;
      top: 50%;
      display: block;
      transform: translateY(-50%);
    }
  `,

  Item: styled.li`
    list-style: none;
    display: inline-flex;
    align-items: center;
    margin: 0 1.2rem 0.4rem 0;

    &:last-of-type {
      margin-right: 0;
    }

    @media only screen and (max-width: 767px) {
      display: block;
      width: 100%;
      text-align: center;
    }

    @media only screen and (min-width: 1550px) {
      display: block;
      width: 100%;
      text-align: right;
    }
  `,

  Icon: styled.span<LegendIconProps>`
    width: 1rem;
    height: 1rem;
    display: inline-flex;
    position: relative;
    margin-right: 0.4rem;

    &:after {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 100%;
      height: ${({ type }) => (type === "line" ? "0.3125rem" : "100%")};
      border-radius: ${({ type }) => (type === "line" ? 0 : "50%")};
      content: "";
      background-color: ${({ color }) => color};
    }
  `,
};
