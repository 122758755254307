import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { getSessionList } from "../../api/sessions";
import PlayerPause from "../../components/icons/play-pause";
import { VRIntlProviderComponent } from "../../components/providers/intl-provider";
import Loader, { CardLoader } from "../../components/ui/loader";
import Page from "../../components/ui/page";
import Stack from "../../components/ui/stack";
import Text from "../../components/ui/text";
import { useDashboardFilters } from "../../context/dashboard-filters";
import { useUser } from "../../context/user";
import { Pages } from "../../enums/pages";
import { Roles } from "../../enums/user";
import { Session } from "../../types/session";
import buildQueryString from "../../utils/query-string";
import useQueryParams from "../../utils/use-query-params";
import SessionsList from "../shared/sessions-list";
import { Container, IconContainer, ScenariosContainer, SessionContainer, SessionContent, Time, TimeStack } from "./sessions-list.style";

const localeFn = (target: string) => import(`./locale/${target.toLowerCase()}.json`);

type SessionEntry = {
  id: string;
  scenarios: Session[];
  name: string;
  from: string;
  to: string;
  duration: string;
};

const List = () => {
  const { state: user } = useUser();
  const { state: filters } = useDashboardFilters();
  const intl = useIntl();
  const history = useHistory();
  const params: any = useQueryParams();

  const userId = params.get("userId");
  const date = params.get("date");

  const { isLoading, data } = useQuery(
    [userId, date],
    () =>
      getSessionList({
        userId: params.get("userId"),
        date: moment(date).format("YYYY-MM-DD"),
      }),
    {
      suspense: false,
      refetchOnMount: false,
      useErrorBoundary: true,
    }
  );

  if (isLoading) return <Loader size="small" />;

  const title = moment(date).format("MMM Do, YYYY") || intl.formatMessage({ id: "session-list:title" });
  const breadcrumbs = [
    {
      label: "Dashboard",
      path: `${user.role === Roles.Instructor ? Pages.DashboardTrainee.replace(":userId", filters.userId || userId) : Pages.Dashboard}`,
    },
    { label: title },
  ];

  if (isLoading) {
    return <Loader size="small" />;
  }

  const handleOnItemClick = ({ session, sessionId }: { session: Session; sessionId: string }) => {
    history.push(
      `${Pages.SessionDetail}${buildQueryString({
        userId: user.role === Roles.Instructor ? userId : user.id,
        from: session.from,
        to: session.to,
        scenarioId: session.scenarioId,
        simulationInstanceId: sessionId,
        scenarioInstanceId: session.id,
        name: encodeURIComponent(session.scenario),
        simulationDate: date,
      })}`
    );
  };

  return (
    <Page title={title}>
      <Container>
        {isLoading && <CardLoader transparent={true} />}
        {data &&
          data.map((session: SessionEntry, index: number) => (
            <SessionContainer key={session.id}>
              <SessionContent>
                <TimeStack>
                  <Text variant="xsRegular">
                    <Time>{moment(session.from).format("HH:mm")}</Time>
                  </Text>
                  <Text variant="xsRegular" color="gray.100">
                    <Time>{moment(session.to).format("HH:mm")}</Time>
                  </Text>
                </TimeStack>

                <IconContainer>
                  <PlayerPause />
                </IconContainer>

                <Stack>
                  <Text variant="medium">
                    <FormattedMessage id={"session-list:session"} />
                    {index + 1}
                  </Text>
                  <Text variant="xsMedium" color="gray.100">
                    <FormattedMessage id={"session-list:duration"} /> <Time>{session.duration}</Time>
                  </Text>
                </Stack>
              </SessionContent>
              <ScenariosContainer>
                <SessionsList
                  data={session.scenarios.map((session: Session) => ({
                    ...session,
                    scenario: session.name || "",
                  }))}
                  sessionId={session.id}
                  onItemClick={handleOnItemClick}
                />
              </ScenariosContainer>
            </SessionContainer>
          ))}
      </Container>
    </Page>
  );
};

const SessionsListPage = () => (
  <VRIntlProviderComponent localeFn={localeFn} id="sessions-list-page" fallback={null}>
    <List />
  </VRIntlProviderComponent>
);

export default SessionsListPage;
