import { IconProps } from "../../types/icon";

const ChevronLeft = ({
  width = 13,
  height = 11,
  fill = "#B0D8A3",
}: IconProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.6239 3.87615C14.0144 4.26667 14.0144 4.89983 13.6239 5.29036L8.49763 10.4166L13.6239 15.5428C14.0144 15.9333 14.0144 16.5665 13.6239 16.957C13.2333 17.3476 12.6002 17.3476 12.2096 16.957L6.37631 11.1237C6.18877 10.9362 6.08341 10.6818 6.08341 10.4166C6.08341 10.1514 6.18877 9.89701 6.37631 9.70948L12.2096 3.87615C12.6002 3.48562 13.2333 3.48562 13.6239 3.87615Z"
      fill="#F2F2F2"
    />
  </svg>
);

export default ChevronLeft;
