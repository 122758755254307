import styled from "styled-components";

export const Item = {
	Container: styled.div`
		width: 100%;
		padding: 0 1rem 0 1rem;
	`,
	Inner: styled.div<{ isLast: boolean }>`
		display: flex;
		flex-direction: column;
		flex: 1;
		${({ theme, isLast }) => !isLast && `border-bottom: 1px solid ${theme.colors.gray[200]}`};
	`,
	Title: styled.h3`
		margin: 1rem 0 0 0;
	`,
	Description: styled.p<{ color: string }>`
		color: ${({ color, theme }) => color || theme.colors.gray[50]};
		text-transform: uppercase;
		margin: 8px 0;
	`,
	Specs: styled.ul`
		padding: 0;
		margin: 0;
		list-style: none;
		display: flex;
		justify-content: flex-end;
	`,
	Spec: styled.li`
		margin: 0.4rem;
	`,
}