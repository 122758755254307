import styled, { keyframes } from "styled-components";

const pulse = keyframes`
  0% {
        opacity: 0.65;
    }
    50% {
        opacity:1 ;
    }
    100% {
        opacity: 0.65;
    }
`;

type CardContainerProps = {
  loading?: boolean | string;
  fluid?: boolean;
};

type CardTitleProps = {
  color?: string;
};

export const Container = styled.div`
  width: 100%;
  padding-bottom: 1rem;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    height: 0.7rem;
  }

  @media screen and (max-width: 1023px) {
    overflow: auto;
    display: block;
    margin-right: -1rem;
    padding-bottom: 0;

    width: calc(100% + 1rem);
  }
`;

export const Card = {
  Inner: styled.div<{ fluid: boolean }>`
    width: 16rem;
    height: 12rem;
    margin-right: 1rem;
    float: left;
    flex: 0 0 auto;
    box-sizing: border-box;

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      width: calc(33.3% - 1rem);
      display: inline-block;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    @media screen and (max-width: 767px) {
      width: calc(50% - 1rem);
      ${({ fluid }) => fluid && `width: calc(100% - 1rem);`}

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    @media screen and (max-width: 1024px) {
      margin-bottom: 1rem;
      height: 8rem;
    }
  `,
  Container: styled.div<CardContainerProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 1rem;
    background-color: ${({ theme }) => theme.colors.gray[300]};
    animation: ${({ loading }) => (loading || loading === "true" ? pulse : "none")} 1s infinite linear;
    overflow: hidden;
    border-radius: 0.6rem;
    pointer-events: none;
    height: 100%;

    ${({ loading }) =>
      loading &&
      `
            justify-content: center;
            align-items: center;
            display: flex !important;
            width: 100% !important;
        `}
  `,
  Title: styled.h2<CardTitleProps>`
    display: flex;
    align-items: center;
    font-weight: 500;
    color: ${({ color, theme }) => color || theme.colors.gray[100]};
    width: 100%;
    font-size: 1.125rem;
    line-height: 1.5rem;

    @media (max-width: 1024px) {
      line-height: 1rem;
      font-size: 0.875rem;
    }
  `,
  Value: styled.div`
    margin: 0;
    font-weight: 300;
    font-family: "Roboto Light", sans-serif;
    font-size: 3.5rem;
    text-align: center;

    @media (max-width: 1023px) {
      font-size: 2rem;
      padding-bottom: 0.8rem;
    }
  `,
  Small: styled.small`
    font-size: 1rem;
    display: block;
    width: 100%;
  `,
  Unit: styled.span`
    font-size: 2rem;
  `,
};

export const GraphContainer = styled.div`
  width: 100%;
  height: 5rem;
  font-size: 1rem;
  margintop: 1rem;
`;
