export enum Features {
  AuthenticationPage = "AUTHENTICATION_PAGE",
  CompletedScenarios = "COMPLETED_SCENARIOS",
  PendingInvites = "PENDING_INVITES",
  Top5Scenarios = "TOP_5_SCENARIOS",
  SettingsPage = "SETTINGS_PAGE",
  SessionChart = "SESSION_CHART",
  SessionList = "SESSION_LIST",
  SessionOverview = "SESSION_OVERVIEW",
  SessionDetailTopStats = "SESSION_DETAIL_TOP_STATS",
  SessionDetailActionsPerformed = "SESSION_DETAIL_ACTIONS_PERFORMED",
  SessionDetailScore = "SESSION_DETAIL_SCORE",
  HideEmptyCharts = "HIDE_EMPTY_CHARTS",
  MinutesSpentAsBarChart = "MINUTES_SPENT_BAR_CHART",
  HideMinutesSpentTraining = "HIDE_MINUTES_SPENT_TRAINING",
  HideUserActions = "HIDE_USER_ACTIONS",
  HideDefaultTopStats = "HIDE_DEFAULT_TOP_STATS",
}
