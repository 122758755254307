import { useCallback, useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { useDetectClickOutside } from "react-detect-click-outside";
import { FormattedMessage, useIntl } from "react-intl";
import { getTrainees } from "../../../api/trainee";
import { SearchIcon, SortIcon, UserCircleIcon, ValidIcon } from "../../../components/icons";
import Avatar from "../../../components/ui/avatar";
import Loader from "../../../components/ui/loader";
import Text from "../../../components/ui/text";
import { useApplication } from "../../../context/application";
import { useDashboardFilters } from "../../../context/dashboard-filters";
import { useFeatureToggles } from "../../../context/feature-toggles";
import { FiltersList } from "../../../enums/dashboard";
import { Pages } from "../../../enums/pages";
import { Trainee, TraineeResponse } from "../../../types/trainee";
import {
  AllTrainees,
  AvatarContainer,
  Container,
  Item,
  Link,
  List,
  ListContainer,
  LoadMore,
  LoaderContainer,
  Name,
  Search,
  Toggle,
  TraineesPopUp,
} from "./filters.styles";
import { buildUrl } from "./utils";

const buildTraineeLink = (id: string | null, filters: any) =>
  `${id ? `${Pages.Dashboard}/trainee/${id}` : Pages.DashboardAllTrainees}${buildUrl(filters)}`;

const mapTraineeResponse = ({ userId, displayName, createDate, email, lastInvitationSent, status }: TraineeResponse): Trainee => ({
  id: userId,
  email,
  name: displayName,
  dateAdded: createDate,
  lastInvitationSent,
  status,
});

const fetchTrainees = async (token: string | null, search: string) => await getTrainees(token, search);

type Props = {
  isOpen: boolean;
  handleOnClick: (name: string | null) => void;
};

const TraineesFilter = ({ isOpen, handleOnClick }: Props) => {
  const { state: filters } = useDashboardFilters();
  const [page, setPage] = useState(1);
  const [token, setToken] = useState(null);
  const [trainees, setTrainees] = useState<Trainee[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [search, setSearch] = useState("");
  const { isFeatureActive } = useFeatureToggles();

  useEffect(() => {
    setIsLoading(true);
    fetchTrainees(token, search)
      .then(({ skipToken, items }) => {
        setToken(skipToken);
        setTrainees([...trainees, ...(items.map((res: TraineeResponse) => mapTraineeResponse(res)) || [])]);
        setIsLoading(false);
      })
      .catch(() => {});
  }, [page, search]);

  const handleSearchChange = useCallback(
    (e: any) => {
      setToken(null);
      setTrainees([]);
      setSearch(e.target.value);
      setPage(1);
    },
    [search]
  );

  const handleToggleList = useCallback(
    (e: any) => {
      handleOnClick(isOpen ? null : FiltersList.TraineesList);
    },
    [isOpen]
  );

  const trainee = trainees.find(({ id }) => id === filters.userId) || null;

  return (
    <Container>
      <Toggle onClick={handleToggleList}>
        {trainee ? (
          <>
            <UserCircleIcon />
            <Name>
              <Text>{trainee.name}</Text>
            </Name>
            <SortIcon />
          </>
        ) : (
          <>
            <UserCircleIcon />
            <Name>
              <FormattedMessage id="dashboard:all-trainees" />
            </Name>
            <SortIcon />
          </>
        )}
      </Toggle>
      {isOpen && (
        <ListPopUp
          search={search}
          onSearchChange={handleSearchChange}
          selected={filters.userId}
          loading={isLoading}
          trainees={trainees}
          token={token}
          onLoadMore={() => setPage(page + 1)}
          onClose={() => handleOnClick(null)}
          onClick={() => handleOnClick(null)}
        />
      )}
    </Container>
  );
};

const ListPopUp = ({
  search,
  onSearchChange,
  selected,
  loading,
  token,
  trainees,
  onLoadMore,
  onClose,
  onClick,
}: {
  search: string;
  onSearchChange: (e: any) => void;
  selected: string;
  loading: boolean;
  token: string | null;
  trainees: Trainee[];
  onLoadMore: () => void;
  onClose: () => void;
  onClick: (e: any) => void;
}) => {
  const intl = useIntl();
  const clickOutsideRef = useDetectClickOutside({ onTriggered: onClose });
  const { dispatch, state: filters } = useDashboardFilters();
  const { setSelectedUser } = useApplication();

  const isAllSelected = selected === null;

  return (
    <TraineesPopUp ref={clickOutsideRef} width={"19.5rem"}>
      <Search>
        <SearchIcon />
        <DebounceInput
          disabled={loading}
          debounceTimeout={200}
          value={search}
          onChange={onSearchChange}
          placeholder={intl.formatMessage({ id: "dashboard:search-for-trainee" })}
        />
      </Search>
      {!search && (
        <AllTrainees selected={isAllSelected} onClick={onClick} to={buildTraineeLink(null, filters)}>
          <FormattedMessage id="dashboard:all-trainees" />
          {isAllSelected && <ValidIcon />}
        </AllTrainees>
      )}
      <ListContainer>
        <List>
          {trainees.map(({ id, name }, i) => (
            <Link
              key={`trainee-${i}-${id}`}
              onClick={(e) => {
                onClick(e);
                setSelectedUser({ id, name });
              }}
              to={buildTraineeLink(id, filters)}
            >
              <Item selected={selected === id}>
                <AvatarContainer>
                  <Avatar name={name} size="small" editable={false} />
                </AvatarContainer>
                <Name className="name">
                  <Text>{name}</Text>
                </Name>
                {selected === id && <ValidIcon />}
              </Item>
            </Link>
          ))}
          {loading && (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          )}
          {!loading && token && (
            <LoaderContainer>
              <LoadMore onClick={onLoadMore}>
                <FormattedMessage id="dashboard:load-more" />
              </LoadMore>
            </LoaderContainer>
          )}
        </List>
      </ListContainer>
    </TraineesPopUp>
  );
};

export default TraineesFilter;
