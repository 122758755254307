import { ReactNode } from "react";
import styled from "styled-components";

type Size = "small" | "medium" | "large";

type SizesProps = {
  [prop: string]: any;
};

const Sizes: SizesProps = {
  small: `
    line-height: 1.75rem;
    border-radius: 1.25rem;
    font-size: 0.75rem;
    padding: 0 1rem;
    font-weight: 500;
  `,
  medium: `
    line-height: 2.5rem;
    border-radius: 1.25rem;
    font-size: 1rem;
    padding: 0 1.5rem;
    font-weight: 500;
  `,
  large: `

  `,
};

type ComponentType = {
  disabled: boolean;
  size: Size;
  variant: string | null | undefined;
};

const Component = styled.button<ComponentType>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  line-height: 2.5rem;
  border-radius: 1.25rem;
  ${({ size }) => Sizes[size]}
  border: none;
  outline: none;
  background-color: ${({ theme, variant }) =>
    (variant && theme.colors.primary[variant][400]) || "transparent"};
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  color: ${({ theme, variant }) =>
    variant ? theme.colors.primary[variant]["text"] : theme.colors.gray[50]};
  border-width: 0.1rem;
  border-style: solid;
  border-color: ${({ theme, variant }) =>
    variant ? theme.colors.primary[variant][400] : theme.colors.gray[50]};

  &:hover {
    background-color: ${({ theme, variant }) =>
    (variant && theme.colors.primary[variant][900]) ||
    theme.colors.gray[200]};
    border-color: ${({ theme, variant }) =>
    variant ? theme.colors.primary[variant][800] : theme.colors.gray[50]};
  }

  &:focus {
    box-shadow: 0 0 0px 2px ${({ theme }) => theme.colors.gray[400]},
      0 0 0px 3px
        ${({ theme, variant }) =>
    theme.colors.primary[variant || "yellow"][400]};
  }

  &[disabled] {
    opacity: 0.7;
    color: ${({ theme, variant }) => theme.colors.gray[variant ? 400 : 100]};
    background-color: ${({ theme, variant }) =>
    (variant && theme.colors.primary[variant][800]) || "transparent"};
    border-color: ${({ theme, variant }) =>
    theme.colors.gray[variant ? 400 : 100]};
    cursor: default;

    & svg path {
      fill: ${({ theme, variant }) => theme.colors.gray[variant ? 400 : 100]};
    }
  }
`;

type ButtonType = {
  disabled?: boolean;
  children: ReactNode;
  variant?: string | null | undefined;
  onClick?: (e: any) => void | undefined;
  size?: Size;
  [prop: string]: any;
};

const Button = ({
  disabled = false,
  children,
  variant,
  onClick,
  size = "medium",
  border = false,
  ...props
}: ButtonType) => (
  <Component
    variant={variant}
    disabled={disabled}
    onClick={onClick}
    size={size}
    {...props}
  >
    {children}
  </Component>
);

export default Button;
