import styled from "styled-components";

export const Container = styled.div`
    text-align: left;
    width: 100%;
`;

type WrapperProps = {
    error: string | null | undefined,
}

export const Wrapper = styled.div < WrapperProps > `
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 3.5rem;
    border-radius: 0.3rem;
    padding: 0 0.8rem 0 0.4rem;
    box-sizing: border-box;
    padding-top: 0;
    position: relative;
    z-index: 98;

    ${({ error, theme }) => error && `box-shadow: 0 0 0px 2px ${theme.colors.gray[400]}, 0 0 0px 3px ${theme.colors.primary.pink[400]}`}
`;

export const Label = styled.div <{
    error: string | null | undefined,
}> `
    margin-top: 0.2rem;
    display: flex;
`;
export const Error = styled.span`
    display: inline-block;
    color: ${({ theme }) => theme.colors.primary.pink[400]};
    margin-top: 0.3rem;
`;

export const Options = styled.ul`
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 0.9rem;
    display: flex;
`;

export const Option = styled.li <{ selected: boolean }>`
    position: relative;
    padding-right: 2rem;
    margin-left: 1rem;
    font-size: 1.125rem;
    cursor: pointer;

    &:before,
    &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        z-index: 98;
    }

    &:before {
        width: 20px;
        height: 20px;
        border: 1px solid ${({ theme }) => theme.colors.gray[50]};
        border-radius: 50%;
    }

    &:after {
        content: '';
        width: 12px;
        height: 12px;
        right: 5px;
        top: 5px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.gray[50]};
        display: none;

        ${({ selected }) => selected === true && `display:block`};
    }
`;