import client from "../../client";
import { parseDashboardFilters } from "../../utils/api";
import sessionDetail from "./../../static/session-detail.json";


export const getDashboardTopStats = async ({ hideDefault, ...filters }) => {

    const { data: defaultTopStats } = hideDefault === false ? await client.get(`/v1/simulation/top-stats${parseDashboardFilters(filters)}`) : { data: [] };
    const { data: customTopStats } = await client.get(`/v1/simulation/custom-top-stats${parseDashboardFilters(filters)}`);

    return [
        ...defaultTopStats,
        ...customTopStats
    ];
}

export const getCustomCharts = async (filters) => {
    const { data } = await client.get(`/v1/simulation/custom-charts${parseDashboardFilters(filters)}`);
    return data;
}

export const getTimeSpent = async (params) => {
    const queryString = Object.keys(params).reduce((acc, key) => {
        if (!params[key]) return acc;
        acc.push(`${key}=${params[key]}`);
        return acc;
    }, []);

    const queryParams = queryString.length > 0 ? `?${queryString.join('&')}` : "";
    const { data } = await client.get(`/v1/simulation/time-spent-chart${queryParams}`);
    return data;
}

export const getTopScenariosPlayed = async (filters) => {
    const { data } = await client.get(`/v1/simulation/top-played-scenarios${parseDashboardFilters(filters)}`);
    return data;
}

export const getTopScenariosCompleted = async (filters) => {
    const { data } = await client.get(`/v1/simulation/top-completed-scenarios${parseDashboardFilters(filters)}`);
    return data;
}

export const getSessionData = async (filters) => {
    const { data } = await client.get(`/v1/simulation/global-timeline${parseDashboardFilters(filters)}`);
    return data;
}

export const getSessionDetailData = async () => {
    return sessionDetail;
}
