import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const FullContainer = styled.div`
  display: flex;
  flex: 1;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const HalfContainer = styled.div`
  min-width: 0;
  display: flex;
  flex: 0.5;
  box-sizing: border-box;
  min-width: 0;

  &:nth-child(even) {
    padding-left: 0.7rem;
  }

  &:nth-child(odd) {
    padding-right: 0.7rem;
  }

  @media (max-width: 1024px) {
    flex: 1;

    &:nth-child(even) {
      padding-left: 0;
    }

    &:nth-child(odd) {
      padding-right: 0;
    }
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 884px) {
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    gap: 1rem;
  }
`;

type ChartContainerProps = {
  empty: boolean;
  autoHeight?: boolean;
};

export const ChartContainer = styled.div<ChartContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;
  height: ${({ empty, autoHeight = false }) =>
    autoHeight || empty ? "auto" : "28rem"};
  box-sizing: border-box;

  & svg.recharts-surface {
    overflow: visible;
  }

  @media (max-width: 767px) {
    height: ${({ empty, autoHeight = false }) =>
      autoHeight || empty ? "auto" : "20rem"};
  }
`;

export const TopStatsTitleContainer = styled.span`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const SeeAllStats = styled.a`
  color: ${({ theme }) => theme.colors.primary.yellow[400]};
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
`;
