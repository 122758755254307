import moment from "moment";
import styled from "styled-components";
import Text from "../../../../components/ui/text";
import { pad } from "../../../../utils/time";

const CustomTooltip = {
	Container: styled.div`
    background-color: ${({ theme }) => theme.colors.gray[400]};
    padding: 0.6rem;
  `,
};

export const BarTooltip = ({ active, payload, value }: any) => {

	if (!active) return null;
	const data = payload[0];
	let v = data.value;

	if (value?.displayMeasurementType === 'Minutes') {
		const duration = moment.duration(v * 60, 'seconds');

		const time = [
			duration.get('minutes'),
			duration.get('seconds'),
		]

		if (value?.currentMeasurementType === 'Milliseconds') {
			time.push(duration.get('milliseconds'))
		}

		v = time.map(t => pad(Math.round(t))).join(':')
	}


	return <CustomTooltip.Container>
		<Text variant="xsRegular">
			{value.description || data.name}
		</Text><br />
		<Text variant="smallRegular">{v}</Text>
	</CustomTooltip.Container>
}