import moment from "moment";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "react-query";

import ChartTooltip from "../chart-tooltip/chart-tooltip";

import { ChartContainer, FullContainer } from "../../dashboard/dashboard.styles";

import { Bar, BarChart, Text as ChartText, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useTheme } from "styled-components";
import { getTimeSpent } from "../../../api/dashboard";
import AreaChart from "../../../components/graphs/area";
import { ClockIcon } from "../../../components/icons";
import { VRIntlProviderComponent } from "../../../components/providers/intl-provider";
import Frame from "../../../components/ui/frame";
import Loader from "../../../components/ui/loader";
import Message from "../../../components/ui/message";
import Styled from "../../../components/ui/styled";
import Text from "../../../components/ui/text";
import { useFeatureToggles } from "../../../context/feature-toggles";
import { Features } from "../../../enums/features";
import useMediaQuery from "../../../utils/hooks/use-media-query";
import { Container } from "../../dashboard/dashboard.styles";
import { CustomTooltip } from "./minutes-spent.styles";

const localeFn = (target: string) =>
  import(`./locale/${target.toLowerCase()}.json`);

type MinutesSpentProps = {
  userId?: string;
  from: String;
  to: String;
}

const MinutesSpentWrapper = ({
  data,
  interval = 7,
  loading,
}: {
  data: {
    name: string,
    value: string | number,
    info: string
  }[],
  interval?: number,
  loading: boolean
}) => {
  const intl = useIntl();
  const hasData = data.length > 0;
  const theme = useTheme();
  const { isFeatureActive } = useFeatureToggles();

  const isBarChart = isFeatureActive(Features.MinutesSpentAsBarChart);

  return <ChartContainer empty={!hasData} autoHeight={isBarChart}>
    {loading && <Loader />}
    {(hasData && (isBarChart ?
      <ResponsiveContainer width="100%" height={data.length * 40}>
        <BarChart data={data.reverse()}
          layout="vertical"
          width={500}
          height={300}
          margin={{ right: 16, left: 140 }}
          barSize={8}>
          <XAxis
            type="number"
            axisLine={false}
            allowDataOverflow
            tick={false}
          />
          <YAxis
            type="category"
            dataKey="name"
            axisLine={false}
            dx={-10}
            textAnchor="end"
            tick={<Tick />}
          />

          <Bar dataKey="value" fill={theme.colors.primary.yellow[400]} />

          <Tooltip
            isAnimationActive={false}
            separator={": "}
            cursor={false}
            content={<TooltipContent />}
          />
        </BarChart>
      </ResponsiveContainer>
      : <AreaChart
        tooltipContent={ChartTooltip}
        interval={interval}
        data={data}
        xAxisLabel={intl.formatMessage({
          id: "minutes-spent:date",
          defaultMessage: "Date",
        })}
      />)) || (
        <Message>
          <FormattedMessage id="no-data" />
        </Message>
      )}
  </ChartContainer>
};

const MinutesSpent = ({ userId, from, to }: MinutesSpentProps) => {
  const isMobile = useMediaQuery("(max-width: 767px)");
  const { isFeatureActive } = useFeatureToggles();

  const { data, isLoading } = useQuery(
    ["getTimeSpent", userId, from, to],
    () => getTimeSpent({ userId, from, to }),
    {
      suspense: true,
      refetchOnMount: false,
      useErrorBoundary: true,
    }
  );

  const dataset =
    (data &&
      data.datasets &&
      data.datasets.map(
        ({
          day,
          totalMilliseconds,
        }: {
          day: string;
          totalMilliseconds: number;
        }) => ({
          name: isMobile
            ? moment(day).format("D/MM")
            : moment(day).format("Do MMM YY"),
          value: Math.round(totalMilliseconds / 1000 / 60),
          info: data.value,
        })
      )) ||
    [];

  const hasData = dataset.length > 0;

  if (!hasData && isFeatureActive(Features.HideEmptyCharts)) {
    return null;
  }

  return <VRIntlProviderComponent
    localeFn={localeFn}
    id="minutes-spent"
    fallback={null}
  >
    <ErrorBoundary fallback={null}>
      <Suspense fallback={null}>
        <FullContainer>
          <Frame title={<FormattedMessage id={"dashboard:minutes-spent"} />} icon={<ClockIcon />}>
            <Container>
              <MinutesSpentWrapper data={dataset} interval={data.xAxisInterval} loading={isLoading} />
            </Container>
          </Frame>
        </FullContainer>
      </Suspense>
    </ErrorBoundary>
  </VRIntlProviderComponent>
}


export default MinutesSpent;


const Tick = (props: any) => {
  const theme = useTheme();
  const { payload: { value } } = props;

  return (
    <ChartText
      {...props}
      fill={theme.colors.gray[50]}
      width={160}>
      {value}
    </ChartText>
  );
}

const TooltipContent = ({ active, payload }: any) => {
  if (!active) return null;
  const data = payload[0];
  return <CustomTooltip.Container>
    <Text variant="xsRegular">
      {data.payload.name}
    </Text><br />
    <Text variant="medium">
      {data.payload.value}
    </Text>
    <Styled textTransform="lowercase" marginLeft="0.2rem">
      <Text variant="xsMedium">
        <FormattedMessage id="minutes" />
      </Text>
    </Styled>

  </CustomTooltip.Container>
}
