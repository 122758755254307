import { ReactNode, useEffect, useRef, useState } from "react";
import { Session, SessionEntry } from "../../../../types/session";
import { sortAlpha } from "../../../../utils/sort";
import { Grid } from "./components/Grid";
import { HorizontalFlowChart } from "./components/HorizontalFlow";
import { VerticalFlowChart } from "./components/VerticalFlow";

export const Events = {
  INIT: "init",
  READY: "ready",
  CLICK: "click",
};

export type Settings = {
  name: string;
  backgroundColor: string;
  gradientColors: string[];
  columnWidth: number;
  rowHeight: number;
  padding: number;
  xLabelGap: number;
  yLabelGap: number;
  interval: number;
  showHours: boolean;
  intervalHours: number;
  barSize: number;
  barGap: number;
  view: "day" | "week" | "month";
  orientation: string;
  dateFormat: string;
  lineColor: string;
  showHorizontalLines: boolean;
  showVerticalLines: boolean;
  textSize: number;
  textColor: string;
  fontFamily: string;
  tooltip: (session: SessionEntry) => ReactNode;
  tooltipWidth: number;
  tooltipHeight: number;
};

type ChartSessionProps = {
  data: any;
  settings?: Partial<Settings>;
  onItemClick: (entry: SessionEntry) => void;
  onDayClick?: (date: Date) => void;
};

export const DAY_MS = 1000 * 3600 * 24;

export const ChartSession = ({ data, settings = {}, onItemClick, onDayClick }: ChartSessionProps) => {
  const svg = useRef<any>(null);
  const [scrollLeft, setScrollLeft] = useState<number>(0);

  const allSettings: Settings = {
    name: `chart-${new Date().getTime()}`,
    backgroundColor: "transparent",
    gradientColors: ["#EE7127", "#FF3958", "#1AB586", "#6486FF"],
    columnWidth: 140,
    rowHeight: 40,
    padding: 40,
    xLabelGap: 120,
    yLabelGap: 40,
    interval: 1,
    showHours: false,
    intervalHours: 1,
    barSize: 10,
    barGap: 2,
    view: "week",
    orientation: "horizontal",
    dateFormat: "DD/MM",
    lineColor: "#383838",
    showHorizontalLines: true,
    showVerticalLines: true,
    textSize: 12,
    textColor: "#f2f2f2",
    fontFamily: "monospace",
    tooltip: (entry) => {
      return <span>{JSON.stringify(entry)}</span>;
    },
    tooltipWidth: 120,
    tooltipHeight: 40,
    ...settings,
  };

  const {
    name,
    backgroundColor,
    gradientColors,
    columnWidth,
    rowHeight,
    padding,
    xLabelGap,
    yLabelGap,
    interval,
    showHours,
    intervalHours,
    orientation,
    dateFormat,
    lineColor,
    showHorizontalLines,
    showVerticalLines,
    textSize,
    textColor,
    fontFamily,
    tooltip,
    tooltipWidth,
    tooltipHeight,
  } = allSettings;

  const sessions = data
    .reduce((acc: Session[], { scenarios }: SessionEntry) => {
      acc.push(...scenarios);
      return acc;
    }, [])
    .sort((a: Session, b: Session) => (new Date(a.from).getTime() > new Date(b.from).getTime() ? 1 : -1));
  const allScenarios: string[] = (sessions || [])
    .reduce((acc: string[], { scenario }: any) => {
      if (acc.indexOf(scenario) < 0) acc.push(scenario);
      return acc;
    }, [])
    .sort(sortAlpha);

  const minDate: Date = sessions.map(({ from }: Session) => new Date(from)).shift() || new Date();
  minDate.setHours(0, 0, 0, 0);

  const maxDate: Date =
    sessions
      .map(({ to }: Session) => new Date(to))
      .sort((a: any, b: any) => (a.getTime() < b.getTime() ? -1 : 1))
      .pop() || new Date();

  const isDaily = interval < 1;
  const totalDays: number = Math.ceil((maxDate.getTime() - minDate.getTime()) / DAY_MS);
  const width: number = columnWidth * totalDays + padding * 2 + xLabelGap;
  const height: number = rowHeight * (allScenarios.length - 1) + padding * 2 + yLabelGap;

  const Component = (orientation === "horizontal" && HorizontalFlowChart) || VerticalFlowChart;

  const colors: string[] = Array.from({ length: allScenarios.length }, (_, i) => gradientColors[i % gradientColors.length]);

  useEffect(() => {
    if (svg.current === null) return;
    svg.current.parentNode.addEventListener("scroll", (e: any) => {
      setScrollLeft(e.target.scrollLeft);
    });
  }, [svg]);

  return (
    <svg style={{ backgroundColor }} ref={svg} width={width} height={height} viewBox={`0 0 ${width} ${height}`} xmlns="http://www.w3.org/2000/svg">
      <style>
        {`
                .y-label,
                .x-label {
                    font: ${textSize};
                    font-family: ${fontFamily};
                    fill: ${textColor};
                }
                .dayArea {
                    fill: rgba(255,255,255,0);
                    cursor: pointer;
                }
                .dayArea:hover {
                    fill: rgba(255,255,255,0.04);
                }
            `}
      </style>
      <defs>
        <linearGradient id={`gradient-${name}`} x1="0" x2="0" y1="0" y2="1">
          {colors.map((color: string, index: number) => (
            <stop key={`stop-color-${name}-${index}`} stopColor={color} offset={`${index * (1 / colors.length) * 100}%`} />
          ))}
        </linearGradient>
        <linearGradient id={`gradient-scroll`} x1="0" x2="1" y1="0" y2="0">
          <stop stopColor={backgroundColor} offset={"0"} />
          <stop stopColor={"transparent"} offset={"100%"} />
        </linearGradient>
      </defs>
      <Grid
        isDaily={isDaily}
        totalDays={totalDays}
        startDate={minDate}
        endDate={maxDate}
        padding={padding}
        scenarios={allScenarios}
        interval={interval}
        rowHeight={rowHeight}
        columnWidth={columnWidth}
        offsetX={xLabelGap}
        offsetY={yLabelGap}
        dateFormat={dateFormat}
        lineColor={lineColor}
        showHorizontalLines={showHorizontalLines}
        showVerticalLines={showVerticalLines}
        showHours={showHours}
        intervalHours={intervalHours}
        orientation={orientation}
        scrollLeft={scrollLeft}
      />
      {
        <Component
          onItemClick={onItemClick}
          name={name}
          data={data}
          startDate={minDate}
          endDate={maxDate}
          settings={allSettings}
          tooltip={tooltip}
          tooltipWidth={tooltipWidth || 120}
          tooltipHeight={tooltipHeight || 40}
        />
      }
      <rect
        opacity={scrollLeft / (xLabelGap + padding)}
        fill={`url(#gradient-scroll)`}
        x={scrollLeft}
        y="0"
        width={(xLabelGap + padding) * 1.1}
        height={height}
      />

      {onDayClick &&
        Array.from({ length: totalDays }, (v, i) => i).map((dayIndex: number) => (
          <rect
            onClick={() => {
              const date = new Date(minDate);
              date.setDate(date.getDate() + dayIndex);
              onDayClick(date);
            }}
            className="dayArea"
            x={dayIndex * columnWidth + xLabelGap + padding - 8}
            y={yLabelGap - (orientation === "vertical" ? 30 : 10)}
            width={columnWidth + 16}
            height={rowHeight * allScenarios.length - yLabelGap + (orientation === "vertical" ? 60 : 20)}
            fill="red"
            rx="5"
          />
        ))}

      {allScenarios.map((name: string, index: number) => (
        <text key={`y-label-${name}-${index}`} className="y-label" x={padding + scrollLeft} y={padding + index * rowHeight + 5}>
          {name}
        </text>
      ))}
    </svg>
  );
};

export type FlowProps = {
  name: string;
  data: SessionEntry[];
  settings: Settings;
  startDate: Date;
  endDate: Date;
  onItemClick: (entry: SessionEntry) => void;
  tooltip: (session: SessionEntry) => ReactNode;
  tooltipWidth: number;
  tooltipHeight: number;
};
