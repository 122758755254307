import { useQuery } from "react-query";
import { getSessionDetailTopStats } from "../../../../api/sessions";
import { TopStatsResponse } from "../../../../types/top-stats";
import useQueryParams from "../../../../utils/use-query-params";
import TopStats from "../../../shared/top-stats";

export const SessionDetailTopStats = () => {
  const query = useQueryParams();

  const scenarioInstanceId = query.get("scenarioInstanceId");
  const simulationId = query.get("simulationInstanceId");
  const userId = query.get("userId");
  const from = query.get("from");
  const to = query.get("to");

  const { isLoading, data } = useQuery<TopStatsResponse>(
    ["getSessionDetailTopStats", from, to, userId, scenarioInstanceId, simulationId],
    () =>
      getSessionDetailTopStats({
        from,
        to,
        userId,
        scenarioInstanceId,
        simulationId,
      }),
    {
      suspense: false,
      refetchOnMount: false,
      useErrorBoundary: true,
      enabled: true,
    }
  );

  return <TopStats data={(!isLoading && data) || []} loading={isLoading} />;
};

export default SessionDetailTopStats;
