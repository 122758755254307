import { ellipsisText } from "../utils";

export const BarYTick = ({ width, ...props }: any) =>
	<text
		x={props.x}
		y={props.y + 5}
		dy={props.dy}
		dx={props.dx}
		fill={props.fill}
		textAnchor={"end"} >
		{ellipsisText(props.payload.value, width)}
	</text>