import { useQuery } from "react-query";
import { getCustomCharts } from "../../../api/dashboard";
import { useDashboardFilters } from "../../../context/dashboard-filters";
import CustomCharts from "../../shared/custom-charts";

const DashboardCharts = () => {
    const { state: filters } = useDashboardFilters();

    const { from, to, userId } = filters;

    const { data } = useQuery(["getCustomCharts", userId, from, to], () => getCustomCharts(filters), {
        suspense: true,
        refetchOnMount: false,
        useErrorBoundary: true,
    });

    if (!data) {
        return null;
    }

    return <CustomCharts charts={data} />
}

export default DashboardCharts;
