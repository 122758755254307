import { UserCircleIcon } from "../../../../components/icons"
import Text from "../../../../components/ui/text"
import { Name, Toggle } from "./user-badge.styles"

const UserBadge = ({ name }: { name: string }) => {
	return <Toggle>
		<UserCircleIcon />
		<Name>
			<Text>{name}</Text>
		</Name>
	</Toggle>
}

export default UserBadge;