import styled from "styled-components";

export const Main = styled.div`
  position: relative;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  border-radius: 0.8rem;
`;

export const ButtonScenarioOverview = styled.button`
  font-size: 0.95rem;
  position: absolute;
  top: -2.9rem;
  right: 0;
  display: flex;
  align-items: center;
  color: #ffffff;
  background: ${({ theme }) => theme.colors.gray[200]};
  padding: 0.5rem 1rem;
  border-radius: 4.25rem;
  border: 0;
  cursor: pointer;
  transition: all 0.2s ease-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[150]};
  }
`;

type SectionHeaderProps = {
  justifyContent?: string;
};

type SectionBodyProps = {
  hasBackground?: boolean;
};

export const Section = {
  Container: styled.div`
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 1rem;
  `,
  Header: styled.div<SectionHeaderProps>`
    font-size: 1rem;
    line-height: 1.25rem;
    color: ${({ theme }) => theme.colors.gray[50]};
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: ${({ justifyContent }) => justifyContent || "flex-start"};
    margin: 0.8rem 0 1.2rem 0;
    font-weight: 500;
  `,
  Body: styled.div<SectionBodyProps>`
    width: 100%;
    box-sizing: border-box;
    ${({ theme, hasBackground }) =>
      hasBackground &&
      `
      background-color:  ${theme.colors.gray[300]}; 
      padding: 1rem 0;
      `}
  `,
};

export const LoaderContainer = styled.div`
  display: flex;
  height: 10rem;
  justify-content: center;
  align-items: center;
`;
