import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { FormattedMessage } from "react-intl";
import { useQuery } from "react-query";
import { getTopScenariosPlayed } from "../../../api/dashboard";
import PieChart from "../../../components/graphs/pie";
import { ClockIcon } from "../../../components/icons";
import Frame from "../../../components/ui/frame";
import Loader from "../../../components/ui/loader";
import Styled from "../../../components/ui/styled";
import { useDashboardFilters } from "../../../context/dashboard-filters";
import { useFeatureToggles } from "../../../context/feature-toggles";
import { Features } from "../../../enums/features";
import { ChartContainer, Container, HalfContainer } from "../dashboard.styles";

const TopScenariosPlayed = () => {
    const { state: filters } = useDashboardFilters();
    const { isFeatureActive } = useFeatureToggles();

    const { from, to, userId } = filters;

    const { data, isLoading } = useQuery(["getTopScenariosPlayed", userId, from, to], () => getTopScenariosPlayed(filters), {
        suspense: true,
        refetchOnMount: false,
        useErrorBoundary: true,
    });

    const dataset = data?.datasets && data.datasets[0].map(({ scenarioName, eventDuration }: { scenarioName: string, eventDuration: number }) => ({
        name: scenarioName,
        value: eventDuration,
        info: data.value,
    })) || [];

    const hasData = dataset.length > 0;

    if (!hasData && isFeatureActive(Features.HideEmptyCharts)) {
        return null;
    }

    return <HalfContainer>
        <Frame title={<FormattedMessage id={"dashboard:top-5-most-played-scenarios"} />} icon={<ClockIcon />}>
            <Container>
                <ChartContainer empty={!hasData}>
                    <ErrorBoundary fallback={null}>
                        <Suspense fallback={null}>
                            {isLoading && <Loader />}
                            {hasData &&
                                <PieChart
                                    data={dataset} />
                                || <Styled color="gray[100]">
                                    <FormattedMessage id="dashboard:no-scenarios" />
                                </Styled>}
                        </Suspense>
                    </ErrorBoundary>
                </ChartContainer>
            </Container>
        </Frame>
    </HalfContainer>
}

export default TopScenariosPlayed;