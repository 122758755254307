import { FormattedMessage } from "react-intl";
import { useTheme } from "styled-components";
import { CrossIcon, ValidIcon } from "../../../../components/icons";
import Styled from "../../../../components/ui/styled";
import { Item } from "./anchor-point.styles";

type AnchorPointProps = {
	[key: string]: any,
	title: string,
	isLast: boolean,
};

const PROPERTIES = ['Inspected', 'Selected']

const AnchorPoint = ({ title, isLast, ...props }: AnchorPointProps) => {
	const theme = useTheme();
	return <Item.Container>
		<Item.Inner isLast={isLast}>
			<Item.Title>{title}</Item.Title>
			<Item.Description color={theme.colors[props.Expired ? 'danger' : 'success']}>
				<FormattedMessage id={`${props.Expired ? `not-` : ''}in-date`} />
			</Item.Description>
			<Item.Specs>
				{PROPERTIES.map((key, index) =>
					<Item.Spec key={`spec-${index}-${key}`}>
						<Styled marginRight="0.6rem">
							{props[key] === true && <ValidIcon /> || <CrossIcon />}
						</Styled>
						<FormattedMessage id={key.toLowerCase()} />
					</Item.Spec>)}
			</Item.Specs>
		</Item.Inner>
	</Item.Container>
}

export default AnchorPoint;