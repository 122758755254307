import { LineTooltipContent } from "../custom-chart.styles";
import { displayLabel, displayUnit } from "../utils";

const LineTooltip = ({ payload, label, data }: any) =>
	!payload || payload.length === 0 ? null :
		<LineTooltipContent.Container>
			<LineTooltipContent.Label>
				{displayLabel(data.label, label)}
			</LineTooltipContent.Label>
			{payload
				.filter((item: any) => item.dataKey !== 'BaseLine')
				.map((item: any) =>
					<LineTooltipContent.Value key={item.dataKey}>
						{item.value > 1 ? (item.value % 1 === 0 ? item.value : Number(item.value).toFixed(2)) : item.value}
						{item.dataKey === data.value.code && displayUnit(data.value)}
					</LineTooltipContent.Value>
				)}
		</LineTooltipContent.Container>

export default LineTooltip;