import { useState } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from "recharts";
import { useTheme } from "styled-components";
import { CustomChartLabelType, CustomChartLegend, CustomChartLegendItem, CustomChartValueType } from "../../../types/custom-charts";
import PieLegend from "./legend/pie-legend";
import { Container, LeftColumn, RightColumn } from "./pie.styles";
import { convertMillisecondsToTimespan } from "../../../utils/functions";

const COLORS = [
  "#EE7127",
  "#F29D37",
  "#EEBA60",
  "#F4EFD2",
];

const ActiveShape = (props: any) => {
  const theme = useTheme();
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
  } = props;

  return (
    <g>
      <text x={cx} y={cy} dy={-12} fontSize={"1.3rem"} textAnchor="middle" fill={theme.colors.gray[50]}>
        {props.name}
      </text>
      <text x={cx} y={cy} dy={12} textAnchor="middle" fill={theme.colors.gray[50]}>
        {formatValue(props.value, props.chartValue)}
      </text>
      <Sector
        {...props}
        innerRadius={innerRadius - 6}
        outerRadius={outerRadius + 6}
      />
    </g>
  );
};

const formatValue = (data: string | number, value: CustomChartValueType | undefined) => {
  if (value?.currentMeasurementType === 'Milliseconds' &&
    value?.displayMeasurementType === "Minutes") {
    return convertMillisecondsToTimespan(Number(data));
  }
  return data;
}

type Props = {
  data: { [prop: string]: any }[],
  label?: CustomChartLabelType,
  value?: CustomChartValueType,
  legend?: CustomChartLegend | null,
}

const VRPieChart = ({ data = [], value, label, legend }: Props) => {
  const theme = useTheme();

  const [activeIndex, setActiveIndex] = useState<number>(-1);

  const labelCode = label && label.code || 'name';
  const valueCode = value && value.code || 'value';

  const defaultColors: string[] = Array.from({ length: data.length }, (_, i) => COLORS[i % COLORS.length]);

  const legendData = data.map((props, i) => {
    const idx: number = legend && legend.items.findIndex((v: CustomChartLegendItem) => v.text === props[labelCode]) || -1;
    const legendItem: CustomChartLegendItem | null = legend && idx > -1 && legend.items[idx] || null;

    return {
      name: legendItem ? legendItem.text : props[labelCode],
      value: formatValue(props[valueCode], value),
      color: legendItem ? legendItem.color : defaultColors[i],
      info: props.info || null
    }
  });

  const onMouseOver = (data: any, index: number) => {
    setActiveIndex(index);
  }

  return <Container>
    <LeftColumn>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            activeIndex={activeIndex}
            data={data}
            dataKey={valueCode}
            nameKey={labelCode}
            cx="50%"
            cy="50%"
            innerRadius="50%"
            outerRadius="80%"
            fill="#8884d8"
            strokeWidth={4}
            stroke={theme.colors.gray[300]}
            onMouseOver={onMouseOver}
            onMouseLeave={() => setActiveIndex(-1)}
            activeShape={<ActiveShape chartValue={value} />}
          >
            {data.map((entry, index) => (
              <Cell style={{outline: 'none'}}  key={`cell-${index}`} fill={legendData[index].color} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </LeftColumn>
    <RightColumn>
      <PieLegend data={legendData} />
    </RightColumn>
  </Container>
};
export default VRPieChart;
