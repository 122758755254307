import moment from "moment";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import ArrowRight from "../../components/icons/arrow-right";
import Trending from "../../components/icons/trending";
import { VRIntlProviderComponent } from "../../components/providers/intl-provider";
import Page, { PageLoader } from "../../components/ui/page";
import Styled from "../../components/ui/styled";
import { useApplication } from "../../context/application";
import { useFeatureToggles } from "../../context/feature-toggles";
import { useUser } from "../../context/user";
import { Features } from "../../enums/features";
import { Pages } from "../../enums/pages";
import { Roles } from "../../enums/user";
import useQueryParams from "../../utils/use-query-params";
import ActionsPerformed from "./components/actions-performed";
import SessionDetailCharts from "./components/custom-charts";
import Score from "./components/score";
import SessionDetailTopStats from "./components/top-stats";
import UserBadge from "./components/user-badge";
import { ButtonScenarioOverview, Main, Section } from "./session-detail.styles";
import { SessionProvider, useSession } from "../../context/session";

const localeFn = (target: string) =>
  import(`./locale/${target.toLowerCase()}.json`);

type Props = {};

const SessionDetail = ({}: Props) => {
  const { state: user } = useUser();
  const { isFeatureActive } = useFeatureToggles();
  const { getSelectedUser } = useApplication();
  const { sessionTotalTimeLabel, sessionPeriodFrom, sessionPeriodTo } =
    useSession();
  const intl = useIntl();
  const history = useHistory();
  const params: any = useQueryParams();
  const name = params.get("name");

  const handleOnClickOverview = () => {
    history.push(
      `${Pages.SessionsOverview}?userId=${params.get(
        "userId"
      )}&name=${encodeURIComponent(
        name
      )}&from=${sessionPeriodFrom}&to=${sessionPeriodTo}`
    );
  };

  const getSubtitleSessionLabel = () => {
    const sessionDate = moment(sessionPeriodFrom || sessionPeriodTo).format(
      "DD/MM/YYYY"
    );
    const fromTime = moment(sessionPeriodFrom).format("HH:mm");
    const toTime = moment(sessionPeriodTo).format("HH:mm");

    return `${sessionDate} · ${fromTime} - ${toTime}`;
  };
  const offset: number = 200;

  const title = name || intl.formatMessage({ id: "session-detail:title" });

  const breadcrumbs = [
    {
      label: "Dashboard",

      path: `${
        user.role === Roles.Instructor
          ? Pages.DashboardTrainee.replace(":userId", params.get("userId"))
          : Pages.Dashboard
      }`,
    },
  ];

  const selectedUser = getSelectedUser();

  return (
    <Page
      title={title}
      showBackButton={true}
      showDetails={true}
      titleDetailText="Session overview"
      subtitleDetailText={getSubtitleSessionLabel()}
      rightContent={selectedUser && <UserBadge name={selectedUser.name} />}
    >
      <Main>
        {isFeatureActive(Features.SessionOverview) && (
          <ButtonScenarioOverview onClick={handleOnClickOverview}>
            <Styled marginRight={"0.65rem"}>
              <FormattedMessage id="session-detail:scenario-overview" />
            </Styled>
            <ArrowRight />
          </ButtonScenarioOverview>
        )}

        {isFeatureActive(Features.SessionDetailTopStats) && (
          <Section.Container>
            <Section.Header justifyContent="space-between">
              <Styled display="flex" alignItems="center">
                <Styled marginRight={"1rem"} display="flex" alignItems="center">
                  <Trending width={22} height={22} />
                </Styled>
                <FormattedMessage id="top-stats" />
              </Styled>
            </Section.Header>
            <Section.Body hasBackground={false}>
              <SessionDetailTopStats />
            </Section.Body>
          </Section.Container>
        )}

        {isFeatureActive(Features.SessionDetailScore) && (
          <Section.Container>
            <Section.Header justifyContent="space-between">
              <Styled display="flex" alignItems="center">
                <Styled marginRight={"1rem"} display="flex" alignItems="center">
                  <Trending width={22} height={22} />
                </Styled>
                <FormattedMessage id="session-detail:score" />
              </Styled>
            </Section.Header>
            <Section.Body hasBackground={false}>
              <Score />
            </Section.Body>
          </Section.Container>
        )}

        {isFeatureActive(Features.SessionDetailActionsPerformed) && (
          <ErrorBoundary
            fallback={
              <Styled color="gray[100]" textAlign="center">
                <FormattedMessage id="session-detail:actions-performed:error" />
              </Styled>
            }
          >
            <Suspense fallback={null}>
              <ActionsPerformed offset={offset} />
            </Suspense>
          </ErrorBoundary>
        )}

        <SessionDetailCharts />
      </Main>
    </Page>
  );
};

const SessionDetailPage = (props: Props) => (
  <VRIntlProviderComponent
    localeFn={localeFn}
    id="session-detail"
    fallback={<PageLoader />}
  >
    <SessionProvider>
      <SessionDetail {...props} />
    </SessionProvider>
  </VRIntlProviderComponent>
);

export default SessionDetailPage;
