export const convertMillisecondsToTimespan = (milliseconds: number) => {
  const seconds = milliseconds / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;

  const restMinutes = Math.floor(minutes % 60);
  const restHours = Math.floor(hours % 24);

  if (hours < 1) {
    return `${String(restMinutes)}m ${String(Math.floor(seconds % 60))}s`;
  }

  if (days >= 1) {
    const intDays = Math.floor(days);
    return `${String(intDays)}d ${String(restHours)}h`;
  }
  const intHours = Math.floor(hours);
  return `${String(intHours)}h ${String(restMinutes)}m`;
};
