import { CustomChartLegend, CustomChartLegendItem } from "../../../../types/custom-charts";
import { Color, Container, Item, List } from "./legend-list.styles";

const LegendList = ({ legend }: { legend: CustomChartLegend }) =>
	<Container>
		<List>
			{legend.items.map(({ text, color, code }: CustomChartLegendItem) =>
				<Item key={code}>
					<Color color={color} />
					{text}
				</Item>)}
		</List>
	</Container>

export default LegendList;