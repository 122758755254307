import { useQuery } from "react-query";
import { getCustomCharts } from "../../../../api/sessions";
import useQueryParams from "../../../../utils/use-query-params";
import CustomCharts from "../../../shared/custom-charts";

const SessionDetailCharts = () => {
  const params: any = useQueryParams();
  let userId = params.get("userId");
  let from: string = params.get("from");
  let to: string = params.get("to");
  let sessionId: string = params.get("sessionId");
  let simulationInstanceId: string = params.get("simulationInstanceId");
  let scenarioInstanceId: string = params.get("scenarioInstanceId");

  const { data } = useQuery(
    ["getCustomCharts", userId, from, to, sessionId, simulationInstanceId, scenarioInstanceId],
    () =>
      getCustomCharts({ from, to, sessionId, userId, simulationInstanceId, scenarioInstanceId }),
    {
      suspense: true,
      refetchOnMount: false,
      useErrorBoundary: true,
    }
  );

  if (!data) return null;

  return <CustomCharts charts={data}></CustomCharts>
};

export default SessionDetailCharts;
