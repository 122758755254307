import moment from 'moment';
import { FormattedMessage } from "react-intl";
import { VRIntlProviderComponent } from "../../components/providers/intl-provider";
import Avatar from "../../components/ui/avatar";
import {
    Column,
    DateAdded,
    Email,
    Header,
    Label,
    LastInvitationSent,
    List,
    ListItem,
    Name,
    Role,
    Status,
    TraineeInfo
} from "./trainees-list.styles";

import ShowIf from '../../components/ui/show-if';
import { useFeatureToggles } from '../../context/feature-toggles';
import { Features } from '../../enums/features';
import useMediaQuery from '../../utils/hooks/use-media-query';
import { Trainee } from "./../../types/trainee";
import Options from './components/options';
import ResendInvite from './components/resend-invite';


const localeFn = (target: string) => import(`./locale/${target.toLowerCase()}.json`);

type ListProps = {
    trainees: Trainee[],
    tab: string
}

type ColumnProps = {
    key: string,
    width: number,
    direction?: string,
    align?: string,
    justify?: string
}

const getColumns = ({ pendingInvitesEnabled, tab }: { pendingInvitesEnabled: boolean, tab: string }): ColumnProps[] => {
    if (pendingInvitesEnabled) {
        return [
            { key: 'name', width: 30, direction: 'row', align: 'center' },
            { key: 'dateAdded', width: 10 },
            { key: 'role', width: 10 },
            { key: 'lastInvitationSent', width: 17 },
            { key: tab === 'pending' ? 'resendInvite' : 'empty', width: 28, justify: 'flex-end' },
            { key: 'options', width: 5, align: 'center', justify: 'center' }
        ]
    }

    return [
        { key: 'name', width: 40, direction: 'row', align: 'center' },
        { key: 'dateAdded', width: 53 },
        { key: 'options', width: 7, align: 'center', justify: 'center' }
    ];
};

const Content = ({ trainee, column, isDevice }: { trainee: Trainee, column: ColumnProps, isDevice: boolean }) => {
    const { isFeatureActive } = useFeatureToggles();
    const { name, email, dateAdded, lastInvitationSent, status } = trainee;
    const { key } = column;

    switch (key) {
        case 'name':
            return <>
                <Avatar
                    editable={false}
                    name={trainee.name}
                    photo={trainee.photo} />
                <TraineeInfo>
                    {name && <Name>{name}</Name>}
                    {email && <Email>{email}</Email>}
                </TraineeInfo>
            </>
        case 'dateAdded':
            return <DateAdded>
                <ShowIf condition={isDevice}>
                    <Label><FormattedMessage id="trainees-list:column_dateAdded" />:</Label>
                </ShowIf>
                {moment(dateAdded).format('MMM D[,] YYYY')}
            </DateAdded>
        case 'role':
            return <Role>
                <ShowIf condition={isDevice}>
                    <Label><FormattedMessage id="trainees-list:column_role" />:</Label>
                </ShowIf>{'Trainee'}
            </Role>
        case 'lastInvitationSent':
            return <LastInvitationSent>
                <ShowIf condition={isDevice}>
                    <Label><FormattedMessage id="trainees-list:column_lastInvitationSent" />:</Label>
                </ShowIf>
                {moment(lastInvitationSent).format('MMM D[,] YYYY')}
            </LastInvitationSent>
        case 'status':
            return <Status>
                {status}
            </Status>
        case 'resendInvite':
            return <ResendInvite email={email} />
        case 'options':
            const HIDE_USER_ACTIONS = isFeatureActive(Features.HideUserActions);
            return !HIDE_USER_ACTIONS && <Options trainee={trainee} /> || null;
        default:
            return null
    }
}

const Trainees = ({ trainees, tab }: ListProps) => {
    const { isFeatureActive } = useFeatureToggles();
    const isDevice = useMediaQuery('(max-width: 1024px)');
    const pendingInvitesEnabled = isFeatureActive('PENDING_INVITES');
    const columns = getColumns({ pendingInvitesEnabled, tab });

    return <List>
        <Header>
            {columns.map(({ width, key, ...props }) =>
                <Column key={key} width={width} align="center">
                    <FormattedMessage id={`trainees-list:column_${key}`} />
                </Column>)}
        </Header>
        {trainees.map((trainee: Trainee, index: number) =>
            <ListItem key={`trainee-${trainee.id}`}>
                {columns.map((column: ColumnProps, index: number) =>
                    <Column {...column} align="center">
                        <Content
                            isDevice={isDevice}
                            trainee={trainee}
                            column={column} />
                    </Column>)}
            </ListItem>
        )}
    </List>;
}

const TraineesList = (props: ListProps) => <VRIntlProviderComponent fallback={null} localeFn={localeFn} id="trainees-list">
    <Trainees {...props} />
</VRIntlProviderComponent>

export default TraineesList;