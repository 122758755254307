import { Passcode } from "./passcode.styles";

type Props = {
	value?: string | null;
}
const PassCode = ({ value }: Props) =>
	<Passcode.Container data-testid='passcode'>
		{value && value.split('').map((char, index) =>
			<Passcode.Char data-testid={`char-${char}-${index}`} key={`char-${index}`}>
				{char}
			</Passcode.Char>)}
	</Passcode.Container>

export default PassCode;