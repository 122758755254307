import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import Calendar from "react-calendar";
import { useDetectClickOutside } from "react-detect-click-outside";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { ArrowLeftIcon, ArrowRightIcon, CalendarIcon, SortIcon } from "../../../components/icons";
import { useDashboardFilters } from "../../../context/dashboard-filters";
import { SET_DATE_PRESET, SET_FILTERS } from "../../../context/dashboard-filters/reducer";
import { DateFilterPresetValues, DateFilterPresets, FiltersList } from "../../../enums/dashboard";
import { CalendarContainer, Columns, DateRangeLabel, Link, Preset, PresetsContainer, PresetsList } from "./date.styles";
import { Container, DateFilterPopUp, Toggle } from "./filters.styles";
import { buildUrl } from "./utils";

type Props = {
  isOpen: boolean;
  handleOnClick: (name: string | boolean) => void;
};

const DateFilter = ({ isOpen, handleOnClick }: Props) => {
  const { state: filters, dispatch: dispatchFilters } = useDashboardFilters();
  const [initialized, setInitialized] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      return;
    }

    const { datePreset } = filters;
    if (filters.datePreset === null) return;

    let value: number = DateFilterPresetValues[datePreset];
    let from: Date = new Date();
    let to: Date = new Date();

    switch (datePreset) {
      case DateFilterPresets.Last7Days:
      case DateFilterPresets.Last14Days:
      case DateFilterPresets.Last30Days:
        from.setDate(from.getDate() + value);
        break;
      case DateFilterPresets.Yesterday:
        from.setDate(from.getDate() + value);
        to.setDate(to.getDate() + value);
        break;
    }

    if (!from || !to) {
      dispatchFilters({ type: SET_DATE_PRESET, payload: null });
      return;
    }

    dispatchFilters({ type: SET_DATE_PRESET, payload: datePreset });
    history.push(buildUrl({ from: from.getTime(), to: to.getTime() }));
  }, [filters.datePreset]);

  const onClose = useCallback(() => {
    if (isOpen) {
      handleOnClick(false);
    }
  }, [isOpen]);

  const handleOnChange = (value: Date[]) => {
    const [from, to] = value;
    dispatchFilters({ type: SET_FILTERS, payload: { from, to } });
    dispatchFilters({ type: SET_DATE_PRESET, payload: null });
    handleOnClick(false);
    history.push(buildUrl({ from: from.getTime(), to: to.getTime() }));
  };

  const handleToggleList = useCallback(
    (e: MouseEvent) => {
      handleOnClick(isOpen ? false : FiltersList.DatePicker);
    },
    [isOpen]
  );

  const handlePresetSelection = useCallback((key: string) => {
    dispatchFilters({ type: SET_DATE_PRESET, payload: key });
    handleOnClick(false);
  }, []);

  const maxDate = new Date();

  const { from, to, datePreset } = filters;
  const clickOutsideRef = useDetectClickOutside({ onTriggered: onClose });
  const hasNoSelection = from === null && to === null && datePreset === null;

  let selectedPreset = filters.datePreset;
  let fromDate = from;
  let toDate = to;

  // set to 7 days by default
  if (hasNoSelection) {
    selectedPreset = DateFilterPresets.Last7Days;
    toDate = new Date();
    fromDate = new Date();
    fromDate.setHours(0);
    fromDate.setDate(fromDate.getDate() + DateFilterPresetValues[DateFilterPresets.Last7Days]);
  }

  return (
    <Container ref={clickOutsideRef}>
      <Toggle onClick={handleToggleList}>
        <CalendarIcon />
        {selectedPreset ? (
          <FormattedMessage id={`dashboard:${selectedPreset}`} />
        ) : (
          <DateRangeLabel>
            {moment(fromDate).format("yyyy-MM-DD")} / {moment(toDate).format("yyyy-MM-DD")}
          </DateRangeLabel>
        )}
        <SortIcon />
      </Toggle>
      {isOpen && (
        <DateFilterPopUp width={"29rem"}>
          <Columns>
            <PresetsContainer>
              <PresetsList>
                {Object.keys(DateFilterPresetValues).map((k: string) => (
                  <Preset key={`preset-${k}`}>
                    <Link selected={k === selectedPreset} onClick={() => handlePresetSelection(k)}>
                      <FormattedMessage id={`dashboard:${k}`} />
                    </Link>
                  </Preset>
                ))}
              </PresetsList>
            </PresetsContainer>
            <CalendarContainer>
              <Calendar
                className="calendar-element"
                selectRange={true}
                onChange={handleOnChange}
                value={[fromDate, toDate]}
                minDate={new Date(2020, 0, 1)}
                maxDate={maxDate}
                showDoubleView={false}
                minDetail={"month"}
                formatShortWeekday={(locale, date) => moment(date).format("dd")}
                nextLabel={<ArrowRightIcon />}
                next2Label={null}
                prevLabel={<ArrowLeftIcon />}
                prev2Label={null}
              />
            </CalendarContainer>
          </Columns>
        </DateFilterPopUp>
      )}
    </Container>
  );
};

export default DateFilter;
