import { DefaultTheme } from "styled-components";

const base: DefaultTheme = {
  fonts: {
    main: "Roboto",
  },
  typography: {
    extraLarge4: {
      fontWeight: 300,
      fontSize: "3.5rem",
      lineHeight: "4.5rem",
    },
    extraLarge3: {
      fontWeight: 700,
      fontSize: "2.5rem",
      lineHeight: "3rem",
    },
    extraLarge2: {
      fontWeight: 300,
      fontSize: "2rem",
      lineHeight: "2.5rem",
    },
    extraLarge: {
      fontWeight: 700,
      fontSize: "1.5rem",
      lineHeight: "2rem",
    },
    large: {
      fontWeight: 700,
      fontSize: "1.25rem",
      lineHeight: "1.5rem",
    },
    medium: {
      fontWeight: 500,
      fontSize: "1.125rem",
      lineHeight: "1.5rem",
    },
    regular: {
      fontWeight: 400,
      fontSize: "1.125rem",
      lineHeight: "1.5rem",
    },
    smallMedium: {
      fontWeight: 500,
      fontSize: "1rem",
      lineHeight: "1.25rem",
    },
    smallRegular: {
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: "1.25rem",
    },
    xsMedium: {
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: "1rem",
    },
    xsRegular: {
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: "1rem",
    },
  },
  colors: {
    primary: {
      yellow: {
        300: "#F7B96E",
        400: "#EE7127",
        800: "#7C3D18",
        900: "#7C3D18",
        text: "#111111",
      },
      pink: {
        400: "#FF647C",
        900: "#8C2361",
        text: "#F2F2F2",
      },
      green: {
        400: "#81842C",
        text: "#F2F2F2",
      },
      dark: {
        400: "#333333",
        800: "#1A1A1A",
        text: "#F2F2F2",
      },
      light: {
        400: "#F2F2F2",
        800: "#FEFEFE",
        text: "#1A1A1A",
      },
      danger: {
        400: "#C13D40",
        800: "#631322",
        text: "#F2F2F2",
      },
      warning: {
        400: "#EE7127",
        800: "#EE7127",
        900: "#EE7127",
      },
    },
    gray: {
      50: "#F2F2F2",
      100: "#999999",
      150: "#555555",
      200: "#383838",
      250: "#333333",
      300: "#1A1A1A",
      400: "#111111",
      500: "#0B0B0B",
    },
    white: "#FFFFFF",
    success: "#81842C",
    error: "#E9646D",
    danger: "#C13D40",
    warning: "#EE7127",
  },
};

export default base;
