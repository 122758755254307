import { ReactNode, createContext, useContext, useReducer } from "react";
import { useQuery } from "react-query";
import { getSessionDetailTopStats } from "../../api/sessions";
import { TopStatsResponse } from "../../types/top-stats";
import { convertMillisecondsToTimespan } from "../../utils/functions";
import useQueryParams from "../../utils/use-query-params";
import { reducer } from "./reducer";

export const initialState = {};

const SessionContext = createContext<any>({});

type Props = {
  children: ReactNode;
};

const SessionProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const query = useQueryParams();

  const scenarioInstanceId = query.get("scenarioInstanceId");
  const simulationId = query.get("simulationInstanceId");
  const userId = query.get("userId");
  const from = query.get("from");
  const to = query.get("to");

  const { isLoading, data = [] } = useQuery<TopStatsResponse>(
    [
      "getSessionDetailTopStats",
      from,
      to,
      userId,
      scenarioInstanceId,
      simulationId,
    ],
    () =>
      getSessionDetailTopStats({
        from,
        to,
        userId,
        scenarioInstanceId,
        simulationId,
      }),
    {
      suspense: false,
      refetchOnMount: false,
      useErrorBoundary: true,
      enabled: true,
    }
  );

  const sessionDurationRow = data.find(
    (item) => item.code === "session-duration"
  );
  let sessionTotalTimeLabel = "";
  if (sessionDurationRow) {
    sessionTotalTimeLabel = convertMillisecondsToTimespan(
      Number(sessionDurationRow.value)
    );
  }

  return (
    <SessionContext.Provider
      value={{
        state,
        dispatch,
        sessionTotalTimeLabel,
        topStats: data,
        isTopStatsLoading: isLoading,
        sessionPeriodFrom: from,
        sessionPeriodTo: to,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

function useSession() {
  const context = useContext(SessionContext);
  if (context === undefined) {
    throw new Error("useSession must be used within a SessionProvider");
  }
  return context;
}

export { SessionProvider, useSession };

