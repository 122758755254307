import styled, { css } from "styled-components";

export const SessionListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0.5rem;
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.primary.dark["800"]};
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
  width: 100%;
`;

export const Duration = styled.p`
  font-size: 1.75rem;
  line-height: 1.5rem;
  font-weight: 300;
  font-family: "Roboto Light", sans-serif;
  margin: 0;
  color: ${({ theme }) => theme.colors.primary.warning[900]};
`;

export const Item = {
  Container: styled.div`
    display: grid;
    grid-template-columns: 56px 1fr;
    gap: 0.5rem;
    grid-template-rows: repeat(2, auto);
    border-radius: 0.5rem;
    padding: 1rem;
    overflow: hidden;
    box-sizing: border-box;
    height: auto;
    box-sizing: border-box;
    min-height: 90px;

    &:first-of-type [data-icon]:before {
      top: 1rem;
    }

    &:last-of-type [data-icon]:before {
      height: 1rem;
      top: -1rem;
    }
  `,
  Content: styled.div`
    grid-row: 1;
    grid-column: 2 / 3;
    padding-left: 1rem;
    flex: 1;
  `,
  Timestamps: styled.div`
    display: flex;
    flex-direction: column;
    padding-right: 1rem;
  `,
  Time: styled.time`
    margin-bottom: -0.3rem;
  `,
  Icon: styled.div`
    width: 1.75rem;
    height: 1.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.gray["200"]};
    border-radius: 50%;
    position: relative;
    margin: 0 1rem;

    &:before {
      content: "";
      position: absolute;
      width: 0;
      height: 100rem;
      border-right: 1px solid ${({ theme }) => theme.colors.gray["200"]};
    }

    & svg {
      z-index: 100;
    }
  `,
  Name: styled.h3`
    margin: 0 0 1rem 0;
  `,
  ImageContainer: styled.div`
    width: 5rem;
    height: 3.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-row: 1 / 2;
    grid-column: 1 / 1;
  `,
  Image: styled.div<{ rounded: boolean }>`
    background-size: cover;
    ${({ rounded }) => css`
      width: ${rounded ? "56px" : "100%"};
      height: ${rounded ? "56px" : "100%"};
      border-radius: ${rounded ? "50%" : "0"};
    `}
  `,
  Info: styled.div`
    display: flex;
    color: ${({ theme }) => theme.colors.gray[100]};
    font-size: 0.875rem;
  `,
  ButtonContainer: styled.div``,
  PeriodLabel: styled.div`
    grid-row: 2;
    grid-column: 1 / 3;
    text-align: right;
    color: ${({ theme }) => theme.colors.gray[50]};
    opacity: 0.36;
    text-align: right;
  `,
};
