import styled from "styled-components";
import Button from "../../../../components/ui/button";

export const Toggle = styled(Button)`
  height: 2.5rem;
  border-radius: 1.25rem;
  border: 1px solid ${({ theme }) => theme.colors.gray[50]};
  width: 10.6rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  pointer-events: none;
`;

export const Name = styled.div`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  margin-left: 0.5rem;
`;
