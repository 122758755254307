import { formatXTickText } from "../utils";

export const LineXTick = (props: any) =>
	<text
		x={props.x}
		y={props.y}
		dy={props.dy}
		fill={props.fill}
		textAnchor={"middle"}>
		{formatXTickText(props.label, props.payload.value)}
	</text>